:root {
  --color-brown: #72685d;
  --color-brown-light: #bfb1a8;
  --color-brown-dark: #5a5551;

  --color-blue: rgb(96, 167, 47);
  --color-blue-light: #7a9da5;
  --color-blue-dark: #293845;

  --color-blue-light-a20: rgba(231, 237, 238, 1);
  --color-brown-light-a40: #bfb1a86e;

  --color-gray-alpha: rgba(0, 0, 0, 0.5);
  --color-blue-light-a50: rgba(122, 157, 165, 0.5);



  --color-blue-back: #f8faff;

  --color-purple: #932cf7;
  --color-red: rgb(224, 16, 16);
  --color-red-dark: rgb(126, 9, 9);

  --color-green: hsl(143, 69%, 34%);

  --color-white: #fff;
  --color-black: #000;
  --color-black-a40: rgba(0, 0, 0, 0.4);

  --color-gray: #e5e5e5;
  --color-gray-dark: #76767D;
  --color-gray-light: #F2F3F5;

}

input[type=text], input[type=date], input[type=password], input[type=number],
select {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
}

input[type=radio] + label {
  margin-left: 8px;
  cursor: pointer;
}

textarea {
  outline: 0px;
  border: 0;
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  resize: none;
  display: inline-block;
  vertical-align: middle;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
